<template>
  <div class="form-group">
    <div class="row">
      <div class="col">
        <label for="sms-body">{{$t('generic-str.content')}}</label>
      </div>
      <div class="col text-right">
        <div class="popup-box-container dropdown" v-if="variables.length">
          <a href="javascript:void(0)" v-dropdown>
            <span class="badge btn-yup-purple">{{$t('sms.send-msg.sms-input-component.lbl-variables')}}</span>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            x-placement="bottom-end"
          >
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              v-for="variable in variables"
              :key="variable.value"
              @click="addVariable(variable)"
            >{{ variable.name }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <ckeditor
      :value="value"
      id="edit"
      :config="configEditor"
      @input="setValue"
    />
  </div>
</template>

<script>
export default {
  name: 'EmailInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    normalize: {
      type: Boolean,
      default: false,
    },
    variables: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      configEditor: {
        fullPage: true,
        allowedContent: true,
        extraPlugins: 'emoji',
        versionCheck: false,
      },
    };
  },
  methods: {
    setValue(value) {
      this.$emit('input', value);
    },
    addVariable(variable) {
      this.setValue(`${this.value}{${variable.value}}`);
    },
  },
};
</script>

<style lang="scss">
.popup-box-container {
  position: relative;
  display: inline-block;
  margin-right: 5px;
}
textarea:not(:last-child) {
  border-bottom: none !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  /* border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; */
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
/* textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
} */
</style>
