<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{$t('warnings.send-component.send-test-email')}}</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div
              class="form-group row"
            >
              <label
                class="
                  control-label
                  m-text-left
                  col-lg-12 col-md-12
                "
                >{{$t('generic-str.lbl-recipient')}}</label
              >
              <div class="col-md-12 col-sm-12">
                <vue-tags-input
                  v-model="nothing"
                  :tags="tags"
                  :max-tags="10"
                  :placeholder="'Ex: marcos@gmail.com,maria@dominio.com'"
                  :separators="[';', ',']"
                  :add-on-key="[13, ':', ';', ',']"
                  @tags-changed="(newTags) => (tags = newTags)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">
              {{$t('generic-str.close')}}
            </button>
            <button type="button" class="btn btn-success" @click="send()">
              Enviar
            </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/mixins/Modal';
import VueTagsInput from '@johmun/vue-tags-input';
import Swal from 'sweetalert2';
import MailService from '@/services/mail.service';

export default {
  components: {
    VueTagsInput,
  },
  mixins: [Modal],
  props: ['id'],
  data() {
    return {
      nothing: '',
      groups: [],
      tags: [],
      form: {},
      isSending: true,
    };
  },
  mounted() {
  },
  created() {
    this.$root.$on('updated.testmail', this.mountPreview);
  },
  methods: {
    mountPreview(email) {
      this.form = email;
    },
    sendRequest() {
      Swal.fire({
        title: this.$t('generic-str.menu.send-msg'),
        text: this.$t('warnings.send-component.send-confirm'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          this.isSending = true;
          MailService.sendMail(this.form)
            .then(
              (send) => {
                this.$toast.show({
                  title: this.$t('generic-str.success'),
                  content: this.$t('warnings.send-component.msg-sent'),
                  type: 'success',
                });
                this.isSending = false;
              },
              (error) => {
                this.$toast.show({
                  title: this.$t('sms.infos.filters.status.lbl-error'),
                  content: error.response.data.message,
                  type: 'danger',
                });
                this.isSending = false;
              },
            )
            .catch(() => {
              this.isSending = false;
            });
        }
      });
    },
    send() {
      console.log(this.form);
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
      if (this.tags.length > 0 && this.form.body.length > 0) {
        const toArray = [];
        this.tags.forEach((element) => {
          toArray.push(element.text);
        });
        this.form.to = toArray.join(', ');
        this.sendRequest();
      } else {
        Toast.fire({
          icon: 'error',
          title: this.$t('warnings.send-component.recipient'),
        });
      }
    },
  },
};
</script>
