<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Preview</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div v-html="body" class="card-template"></div>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">
              {{$t('generic-str.close')}}
            </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/mixins/Modal';

export default {
  components: {
  },
  mixins: [Modal],
  props: ['id'],
  data() {
    return {
      groups: [],
      body: '',
      isSending: true,
    };
  },
  mounted() {
  },
  created() {
    this.$root.$on('updated.previewmail', this.mountPreview);
  },
  methods: {
    mountPreview(email) {
      this.body = email.body;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .card-template img {
  object-fit: contain;
}

.modal-open {
  overflow: auto !important;
}
</style>
